/*-------- Compare style ---------*/

.compare-table {
  & .table {
    & tbody {
      & tr {
        & th {
          &.title-column {
            min-width: 292px;
            font-size: 15px;
            color: #262626;
            margin: 0;
            background-color: #e6e6e6;
            font-weight: 500;
            padding: 10px 30px;
            border: none;
            @media #{$md-layout} {
              min-width: 200px;
            }
            @media #{$xs-layout} {
              min-width: 150px;
              padding: 10px 20px;
            }
          }
        }
        &:first-child th {
          padding-top: 53px;
        }
        &:last-child th {
          padding-bottom: 55px;
        }
        & td {
          text-align: center;
          padding: 17px 15px;
          border-color: #e7e7e7;
          &.product-image-title {
            padding: 27px 10px 35px;
            @media #{$xs-layout} {
              min-width: 220px;
            }
            .compare-remove {
              a {
                font-size: 13px;
                i {
                  padding-right: 5px;
                }
                &:hover {
                  color: $theme-color;
                }
              }
              button {
                background: none;
                border: none;
                padding: 0;
                i {
                  font-size: 25px;
                }
                &:hover {
                  color: red;
                }
              }
            }
            & .image {
              clear: both;
              width: 100%;
              margin: 14px 0 7px;
              display: block;
              img {
                max-width: 300px;
                width: auto;
              }
            }
            & .category {
              float: left;
              clear: both;
              color: $theme-color;
              text-transform: capitalize;
              letter-spacing: 0.5px;
            }
            & .product-title {
              display: block;
              overflow: hidden;
              > a {
                float: left;
                clear: both;
                font-size: 16px;
                font-weight: 500;
                text-transform: capitalize;
                margin-top: 5px;
                display: block;
                width: 100%;
                &:hover {
                  color: $theme-color;
                }
              }
            }
            .compare-btn {
              margin: 14px 0 0;
              a,
              button {
                background-color: $theme-color;
                padding: 10px 15px;
                border-radius: 50px;
                color: #fff;
                display: inline-block;
                border: none;
                &:hover,
                &.active {
                  background-color: #363f4d;
                }

                &:disabled {
                  cursor: not-allowed;
                }

                &.disabled {
                  opacity: 0.3;
                  &:hover {
                    background-color: #343538;
                  }
                }
              }
            }
          }
          &.product-desc {
            & p {
              margin: 0;
              font-size: 13px;
              line-height: 1.88;
            }
          }
          &.product-price {
            font-size: 14px;
            font-weight: 400;
            color: #343538;

            .amount {
              &.old {
                text-decoration: line-through;
                margin-right: 10px;
                color: #8e8e8e;
              }
            }
          }
          &.product-sku,
          &.product-stock,
          &.product-weight {
            font-size: 13px;
            font-weight: 400;
          }
          &.product-dimensions {
            font-size: 13px;
            font-weight: 400;
          }

          &.product-rating {
            i {
              color: #343538;
              margin-right: 5px;

              &.yellow {
                color: #ffa900;
              }
            }
          }
        }
      }
    }
  }
}
/*------ end Compare Page Wrapper -----*/
