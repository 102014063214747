/*--- preloader ---*/
.cssload-dots {
  width: 0;
  height: 0;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  outline: 0px red;
  filter: url(#goo);
  -o-filter: url(#goo);
  -ms-filter: url(#goo);
  -webkit-filter: url(#goo);
  -moz-filter: url(#goo);
}

.cssload-dot {
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
}
.cssload-dot:before {
  content: "";
  width: 35px;
  height: 35px;
  border-radius: 4px;
  background: #1C9E49;
  position: absolute;
  left: 50%;
  transform: translateY(0);
  -o-transform: translateY(0);
  -ms-transform: translateY(0);
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  margin-left: -19.5px;
  margin-top: -19.5px;
}

.cssload-dot:nth-child(5):before {
  z-index: 100;
  width: 56.5px;
  height: 56.5px;
  margin-left: -25.75px;
  margin-top: -25.75px;
  animation: cssload-dot-colors 3s ease infinite;
  -o-animation: cssload-dot-colors 3s ease infinite;
  -ms-animation: cssload-dot-colors 3s ease infinite;
  -webkit-animation: cssload-dot-colors 3s ease infinite;
  -moz-animation: cssload-dot-colors 3s ease infinite;
}

.cssload-dot:nth-child(1) {
  animation: cssload-dot-rotate-1 1.2s 0s linear infinite;
  -o-animation: cssload-dot-rotate-1 3s 0s linear infinite;
  -ms-animation: cssload-dot-rotate-1 3s 0s linear infinite;
  -webkit-animation: cssload-dot-rotate-1 3s 0s linear infinite;
  -moz-animation: cssload-dot-rotate-1 3s 0s linear infinite;
}
.cssload-dot:nth-child(1):before {
  background-color: #1E1E1E;
  animation: cssload-dot-move 1.2s 0s ease infinite;
  -o-animation: cssload-dot-move 3s 0s ease infinite;
  -ms-animation: cssload-dot-move 3s 0s ease infinite;
  -webkit-animation: cssload-dot-move 3s 0s ease infinite;
  -moz-animation: cssload-dot-move 3s 0s ease infinite;
}

.cssload-dot:nth-child(2) {
  animation: cssload-dot-rotate-2 1.2s 0.75s linear infinite;
  -o-animation: cssload-dot-rotate-2 3s 0.75s linear infinite;
  -ms-animation: cssload-dot-rotate-2 3s 0.75s linear infinite;
  -webkit-animation: cssload-dot-rotate-2 3s 0.75s linear infinite;
  -moz-animation: cssload-dot-rotate-2 3s 0.75s linear infinite;
}
.cssload-dot:nth-child(2):before {
  background-color: #1C9E49;
  animation: cssload-dot-move 1.2s 0.75s ease infinite;
  -o-animation: cssload-dot-move 3s 0.75s ease infinite;
  -ms-animation: cssload-dot-move 3s 0.75s ease infinite;
  -webkit-animation: cssload-dot-move 3s 0.75s ease infinite;
  -moz-animation: cssload-dot-move 3s 0.75s ease infinite;
}

.cssload-dot:nth-child(3) {
  animation: cssload-dot-rotate-3 2.2s 1.5s linear infinite;
  -o-animation: cssload-dot-rotate-3 3s 1.5s linear infinite;
  -ms-animation: cssload-dot-rotate-3 3s 1.5s linear infinite;
  -webkit-animation: cssload-dot-rotate-3 3s 1.5s linear infinite;
  -moz-animation: cssload-dot-rotate-3 3s 1.5s linear infinite;
}
.cssload-dot:nth-child(3):before {
  background-color: #1E1E1E;
  animation: cssload-dot-move 2.2s 1.5s ease infinite;
  -o-animation: cssload-dot-move 3s 1.5s ease infinite;
  -ms-animation: cssload-dot-move 3s 1.5s ease infinite;
  -webkit-animation: cssload-dot-move 3s 1.5s ease infinite;
  -moz-animation: cssload-dot-move 3s 1.5s ease infinite;
}

.cssload-dot:nth-child(4) {
  animation: cssload-dot-rotate-4 3s 2.25s linear infinite;
  -o-animation: cssload-dot-rotate-4 3s 2.25s linear infinite;
  -ms-animation: cssload-dot-rotate-4 3s 2.25s linear infinite;
  -webkit-animation: cssload-dot-rotate-4 3s 2.25s linear infinite;
  -moz-animation: cssload-dot-rotate-4 3s 2.25s linear infinite;
}
.cssload-dot:nth-child(4):before {
  background-color: #1C9E49;
  animation: cssload-dot-move 3s 2.25s ease infinite;
  -o-animation: cssload-dot-move 3s 2.25s ease infinite;
  -ms-animation: cssload-dot-move 3s 2.25s ease infinite;
  -webkit-animation: cssload-dot-move 3s 2.25s ease infinite;
  -moz-animation: cssload-dot-move 3s 2.25s ease infinite;
}

@keyframes cssload-dot-move {
  0% {
    transform: translateY(0);
  }
  18%,
  22% {
    transform: translateY(-79px);
  }
  40%,
  100% {
    transform: translateY(0);
  }
}

@-o-keyframes cssload-dot-move {
  0% {
    -o-transform: translateY(-79);
  }
  18%,
  22% {
    -o-transform: translateY(-79px);
  }
  40%,
  100% {
    -o-transform: translateY(0);
  }
}

@-ms-keyframes cssload-dot-move {
  0% {
    -ms-transform: translateY(0);
  }
  18%,
  22% {
    -ms-transform: translateY(-79px);
  }
  40%,
  100% {
    -ms-transform: translateY(0);
  }
}

@-webkit-keyframes cssload-dot-move {
  0% {
    -webkit-transform: translateY(0);
  }
  18%,
  22% {
    -webkit-transform: translateY(-79px);
  }
  40%,
  100% {
    -webkit-transform: translateY(0);
  }
}

@-moz-keyframes cssload-dot-move {
  0% {
    -moz-transform: translateY(0);
  }
  18%,
  22% {
    -moz-transform: translateY(-79px);
  }
  40%,
  100% {
    -moz-transform: translateY(0);
  }
}

@keyframes cssload-dot-colors {
  0% {
    background-color: #1C9E49;
  }
  25% {
    background-color: #1E1E1E;
  }
  50% {
    background-color: #1C9E49;
  }
  75% {
    background-color: #1E1E1E;
  }
  100% {
    background-color: #1C9E49;
  }
}

@-o-keyframes cssload-dot-colors {
  0% {
    background-color: #1C9E49;
  }
  25% {
    background-color: #1E1E1E;
  }
  50% {
    background-color: #1C9E49;
  }
  75% {
    background-color: #1E1E1E;
  }
  100% {
    background-color: #1C9E49;
  }
}

@-ms-keyframes cssload-dot-colors {
  0% {
    background-color: #1C9E49;
  }
  25% {
    background-color: #1E1E1E;
  }
  50% {
    background-color: #1C9E49;
  }
  75% {
    background-color: #1E1E1E;
  }
  100% {
    background-color: #1C9E49;
  }
}

@-webkit-keyframes cssload-dot-colors {
  0% {
    background-color: #1C9E49;
  }
  25% {
    background-color: #1E1E1E;
  }
  50% {
    background-color: #1C9E49;
  }
  75% {
    background-color: #1E1E1E;
  }
  100% {
    background-color: #1C9E49;
  }
}

@-moz-keyframes cssload-dot-colors {
  0% {
    background-color: #1C9E49;
  }
  25% {
    background-color: #1E1E1E;
  }
  50% {
    background-color: #1C9E49;
  }
  75% {
    background-color: #1E1E1E;
  }
  100% {
    background-color: #1C9E49;
  }
}

@keyframes cssload-dot-rotate-1 {
  0% {
    transform: rotate(-105deg);
  }
  100% {
    transform: rotate(270deg);
  }
}

@-o-keyframes cssload-dot-rotate-1 {
  0% {
    -o-transform: rotate(-105deg);
  }
  100% {
    -o-transform: rotate(270deg);
  }
}

@-ms-keyframes cssload-dot-rotate-1 {
  0% {
    -ms-transform: rotate(-105deg);
  }
  100% {
    -ms-transform: rotate(270deg);
  }
}

@-webkit-keyframes cssload-dot-rotate-1 {
  0% {
    -webkit-transform: rotate(-105deg);
  }
  100% {
    -webkit-transform: rotate(270deg);
  }
}

@-moz-keyframes cssload-dot-rotate-1 {
  0% {
    -moz-transform: rotate(-105deg);
  }
  100% {
    -moz-transform: rotate(270deg);
  }
}

@keyframes cssload-dot-rotate-2 {
  0% {
    transform: rotate(165deg);
  }
  100% {
    transform: rotate(540deg);
  }
}

@-o-keyframes cssload-dot-rotate-2 {
  0% {
    -o-transform: rotate(165deg);
  }
  100% {
    -o-transform: rotate(540deg);
  }
}

@-ms-keyframes cssload-dot-rotate-2 {
  0% {
    -ms-transform: rotate(165deg);
  }
  100% {
    -ms-transform: rotate(540deg);
  }
}

@-webkit-keyframes cssload-dot-rotate-2 {
  0% {
    -webkit-transform: rotate(165deg);
  }
  100% {
    -webkit-transform: rotate(540deg);
  }
}

@-moz-keyframes cssload-dot-rotate-2 {
  0% {
    -moz-transform: rotate(165deg);
  }
  100% {
    -moz-transform: rotate(540deg);
  }
}

@keyframes cssload-dot-rotate-3 {
  0% {
    transform: rotate(435deg);
  }
  100% {
    transform: rotate(810deg);
  }
}

@-o-keyframes cssload-dot-rotate-3 {
  0% {
    -o-transform: rotate(435deg);
  }
  100% {
    -o-transform: rotate(810deg);
  }
}

@-ms-keyframes cssload-dot-rotate-3 {
  0% {
    -ms-transform: rotate(435deg);
  }
  100% {
    -ms-transform: rotate(810deg);
  }
}

@-webkit-keyframes cssload-dot-rotate-3 {
  0% {
    -webkit-transform: rotate(435deg);
  }
  100% {
    -webkit-transform: rotate(810deg);
  }
}

@-moz-keyframes cssload-dot-rotate-3 {
  0% {
    -moz-transform: rotate(435deg);
  }
  100% {
    -moz-transform: rotate(810deg);
  }
}

@keyframes cssload-dot-rotate-4 {
  0% {
    transform: rotate(705deg);
  }
  100% {
    transform: rotate(1080deg);
  }
}

@-o-keyframes cssload-dot-rotate-4 {
  0% {
    -o-transform: rotate(705deg);
  }
  100% {
    -o-transform: rotate(1080deg);
  }
}

@-ms-keyframes cssload-dot-rotate-4 {
  0% {
    -ms-transform: rotate(705deg);
  }
  100% {
    -ms-transform: rotate(1080deg);
  }
}

@-webkit-keyframes cssload-dot-rotate-4 {
  0% {
    -webkit-transform: rotate(705deg);
  }
  100% {
    -webkit-transform: rotate(1080deg);
  }
}

@-moz-keyframes cssload-dot-rotate-4 {
  0% {
    -moz-transform: rotate(705deg);
  }
  100% {
    -moz-transform: rotate(1080deg);
  }
}
