.default-button {
  background-color: #f2f2f2;
  border: medium none;
  color: #000;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  padding: 16px 35px 17px;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.3s ease 0s;
  &:hover {
    background: $theme-color;
    color: #fff;
  }
}
