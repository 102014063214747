.error-btn {
  position: relative;
  transition: all 0.3s;
  color: #fff;
  font-size: 16px;
  min-width: 184px;
  min-height: 55px;
  line-height: 55px;
  margin-bottom: 6px;
  padding: 0 10px;
  border: none;
  border-radius: $btn-border-radius;
  background-color: $theme-color;
  display: inline-block;
  &:hover {
    background-color: #111111;
    color: #fff;
    &:after {
      background-color: #111111;
    }
  }
}