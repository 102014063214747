@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,800');

// Font Family
$poppins: "Poppins", sans-serif;
$josefin: "Josefin Sans", sans-serif;

// text Colors ---------------

$white: #ffffff;
$black: #000000;
$grey: #f6f7f8;
$red: #f6f7f8;
$green: #f6f7f8;

$text-header: #3B3F33;

// bg Colors ---------------

$bg-white: #ffffff;
$bg-black: #000000;
$bg-grey: #f6f6f8;
$bg-purple: #f0e0ff;

// Shadows
$bg-shadow: 0 0 1rem 0 #3b3f332b;
$bg-shadow-light: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0px 0 1px rgb(10 10 10 / 2%);
$product-shadow: 0 6px 20px rgb(84 84 84 / 0%);

// Heading Color
$heading-color: #000000;

// Theme Color
$theme-color: #6ba6ff;
$theme-color-secondary: #6ba6ff;
$theme-text: #3B3F33;
$theme-btn: #4B5A57;
$theme-btn-cta: #6ba6ff;
$theme-btn-cta-text: #fff;
$theme-block: #FFF;
$theme-title: $theme-color;
$theme-header: #FFF;

// Responsive Variables
$xx-layout: "only screen and (min-width: 1366px) and (max-width: 1600px)";
$xl-layout: "only screen and (min-width: 1200px) and (max-width: 1365px)";
$lg-layout: "only screen and (min-width: 992px) and (max-width: 1199px)";
$md-layout: "only screen and (min-width: 768px) and (max-width: 991px)";
$xs-layout: "only screen and (max-width: 767px)";
$sm-layout: "only screen and (min-width: 576px) and (max-width: 767px)";
$xxs-layout: "only screen and (min-width: 320px) and (max-width: 479px)";

// Border radius
$btn-border-radius: 0px;
$card-border-radius: 10px;
