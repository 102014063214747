/*----- 20. Cart page  -----*/

h3.cart-page-title {
  font-size: 20px;
  font-weight: 500;

  margin: 0 0 15px;
}

.cart-table-content {
  table {
    border: 1px solid #ebebeb;
    thead > tr {
      border: 1px solid #ebebeb;
      background-color: #f9f9f9;
      th {
        font-size: 14px;
        font-weight: 500;

        padding: 21px 45px 22px;

        text-align: center;
        vertical-align: middle;
        white-space: nowrap;
        text-transform: uppercase;

        color: #333;
        border-top: medium none;
        @media #{$lg-layout} {
          padding: 21px 35px 22px;
        }
        @media #{$xs-layout} {
          padding: 21px 20px 22px;
        }
      }
    }
    tbody > tr {
      border-bottom: 1px solid #ebebeb;
      td.product-thumbnail {
        width: 150px;
      }
      td.product-name {
        width: 435px;

        a {
          font-size: 15px;
          font-weight: 500;

          color: #333;
          &:hover {
            color: $theme-color;
          }
        }
      }
      td.product-price-cart {
        width: 435px;
        span {
          font-weight: 500;

          color: #333;
          &.old {
            margin-right: 10px;

            text-decoration: line-through;

            color: #8e8e8e;
          }
        }
      }
      td.product-subtotal {
        font-weight: 500;

        color: #333;
      }
      td.product-quantity {
        width: 435px;
        .cart-plus-minus {
          position: relative;

          display: inline-block;

          width: 110px;
          height: 40px;
          padding: 0;
          .qtybutton {
            font-size: 16px;

            position: absolute;

            float: inherit;

            width: 20px;
            margin: 0;

            cursor: pointer;
            transition: all 0.3s ease 0s;
            text-align: center;

            color: #333;
            border: none;
            background: none;
          }
          .dec.qtybutton {
            top: 0;
            left: 0;

            height: 40px;

            border-right: 1px solid #e5e5e5;
          }
          .inc.qtybutton {
            top: 0;
            right: 0;

            height: 40px;

            border-left: 1px solid #e5e5e5;
          }
          input.cart-plus-minus-box {
            font-size: 14px;

            float: left;

            width: 110px;
            height: 40px;
            margin: 0;
            padding: 0;

            text-align: center;

            color: #333;
            border: 1px solid #e1e1e1;
            background: transparent none repeat scroll 0 0;
            border-radius: $btn-border-radius;
          }
        }
      }
      td.product-remove {
        width: 100px;
        a,
        button {
          font-size: 17px;

          margin: 0 13px;

          color: #666;
          border: none;
          background: none;
          &:hover {
            color: $theme-color;
          }
        }
      }
      td.product-wishlist-cart > a,
      td.product-wishlist-cart > button {
        font-size: 13px;
        font-weight: 500;
        line-height: 1.2;

        display: block;

        margin: 0 auto;
        padding: 10px 15px;

        text-transform: uppercase;

        color: #fff;
        border: none;
        border-radius: 50px;
        background: none;
        background-color: $theme-color;
        &:hover,
        &.active {
          background-color: #333;
        }
        &:disabled {
          cursor: not-allowed;
        }
      }
      td {
        font-size: 15px;

        padding: 30px 0 30px 30px;

        text-align: center;

        color: #333;
      }
    }
  }
}

.cart-shiping-update-wrapper {
  display: flex;
  justify-content: space-between;

  padding: 30px 0 55px;
  @media #{$xs-layout} {
    display: block;

    padding: 30px 0 15px;
  }
  .cart-shiping-update > a,
  .cart-clear > button,
  .cart-clear > a {
    font-size: 14px;
    font-weight: 500;
    line-height: 1;

    display: inline-block;

    padding: 18px 63px 17px;

    text-transform: uppercase;

    color: #363f4d;
    border-radius: 50px;
    background-color: #f2f2f2;
    @media #{$md-layout} {
      font-size: 13px;

      padding: 18px 25px 17px;
    }
    @media #{$xs-layout} {
      margin: 0 0 15px;
      padding: 18px 40px 17px;
    }
    &:hover {
      color: #fff;
      background-color: $theme-color;
    }
  }
  .cart-clear > button {
    margin-right: 27px;

    cursor: pointer;
    transition: all 0.3s ease 0s;

    border: medium none;
    &:last-child {
      margin-right: 0;
    }
    @media #{$md-layout} {
      margin-right: 15px;
    }
  }
}

.grand-totall {
  padding: 45px 30px 50px;

  border: 1px solid #ebebeb;
  border-radius: 5px;
  background-color: #f9f9f9;
  @media #{$lg-layout} {
    padding: 45px 18px 50px;
  }
  @media #{$md-layout} {
    margin-top: 30px;
  }
  .title-wrap {
    position: relative;
    &::before {
      position: absolute;
      z-index: 1;
      top: 10px;
      left: 0;

      width: 100%;
      height: 1px;

      content: "";
      transition: all 0.4s ease 0s;

      background-color: #e3e1e1;
    }
    h4.cart-bottom-title {
      font-size: 18px;
      font-weight: 500;

      display: inline-block;

      margin: 0;
      padding-right: 18px;
    }
    .section-bg-gary-cart {
      position: relative;
      z-index: 9;

      background-color: #f9f9f9;
    }
  }
  h5 {
    font-size: 14px;

    margin: 36px 0 27px;
    span {
      font-size: 18px;
      font-weight: 500;

      float: right;
    }
  }
  .total-shipping {
    margin: 0 0 27px;
    padding: 28px 0;

    border-top: 1px solid #ebebeb;
    border-bottom: 1px solid #ebebeb;
    h5 {
      font-size: 14px;

      margin: 0;
    }
    ul {
      padding: 19px 0 0 0;
      li {
        margin: 0 0 6px;

        list-style: outside none none;

        color: #242424;
        &:last-child {
          margin: 0 0 0;
        }
        input {
          position: relative;
          top: 2px;

          width: 13px;
          height: 13px;
          margin-right: 10px;
          padding: 0;

          cursor: pointer;

          color: #626262;
          border: 1px solid #d7d7d7;
          border-radius: 5px !important;
          background: #e9e9e9 none repeat scroll 0 0;
        }
        span {
          float: right;
        }
      }
    }
  }
  h4.grand-totall-title {
    font-size: 20px;
    font-weight: 500;

    margin: 0 0 25px;

    color: $theme-color;
    span {
      float: right;
    }
  }
  a {
    font-size: 14px;
    font-weight: 500;
    line-height: 1;

    display: block;

    padding: 18px 10px 17px;

    text-align: center;
    text-transform: uppercase;

    color: #fff;
    border-radius: 50px;
    background-color: $theme-color;
    &:hover {
      background-color: $theme-btn;
    }
  }
}
